import { CountryCode, CountryFlagProvider } from '@sonnen/shared-web';

export enum CountryFeatureName {
  ANALYSIS_BACKUP_BOX_CHART = 'AnalysisBackupBoxChart',
  COMMUNITY_NEWS = 'CommunityNews',
  CONTACT_FORM = 'ContactForm',
  CUSTOMER_GUIDANCE = 'CustomerGuidance',
  DASHBOARD_CHARGER = 'DashboardCharger',
  EMERGENCY_MODE = 'EmergencyMode',
  FEEDBACK = 'Feedback',
  HELP_AND_SUPPORT_CONTACT_INFO = 'HelpAndSupportContactInfo',
  MEASUREMENTS_BANNER = 'MeasurementsBanner',
  RECRUIT_FRIENDS = 'RecruitFriends',
  RELEASE_NOTES = 'ReleaseNotes',
  SONNEN_DRIVE = 'SonnenDrive',
  SYSTEM_DOCUMENTS = 'SystemDocuments',
  TAXATION_HELP = 'TaxationHelp',
}

CountryFlagProvider.createFeaturesConfiguration({
  [CountryFeatureName.ANALYSIS_BACKUP_BOX_CHART]: {
    blacklist: {
      user: [
        CountryCode.US,
      ],
    },
  },
  [CountryFeatureName.COMMUNITY_NEWS]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.CONTACT_FORM]: {
    whitelist:  {
      user: [],
    },
  },
  [CountryFeatureName.CUSTOMER_GUIDANCE]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.DASHBOARD_CHARGER]: {
    whitelist: {
      battery: [CountryCode.DE],
    },
  },
  [CountryFeatureName.EMERGENCY_MODE]: {
    whitelist: {
      battery: [
        CountryCode.US,
        CountryCode.CA,
        CountryCode.PR,
      ],
    },
  },
  [CountryFeatureName.FEEDBACK]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.HELP_AND_SUPPORT_CONTACT_INFO]: {
    blacklist: {
      user: [CountryCode.AT, CountryCode.CH, CountryCode.DE],
    },
  },
  [CountryFeatureName.MEASUREMENTS_BANNER]: {
    whitelist: {
      battery: [CountryCode.DE],
      user: [],
    },
  },
  [CountryFeatureName.RECRUIT_FRIENDS]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.RELEASE_NOTES]: {
    blacklist: {
      battery: [CountryCode.US, CountryCode.PR],
    },
  },
  [CountryFeatureName.SONNEN_DRIVE]: {
    whitelist: {
      user: [CountryCode.DE],
    },
  },
  [CountryFeatureName.SYSTEM_DOCUMENTS]: {
    whitelist: {
      user: [CountryCode.IT],
    },
  },
  [CountryFeatureName.TAXATION_HELP]: {
    whitelist: {
      battery: [CountryCode.DE],
    },
  },
});
