import { getOpportunities } from '+documents/store/documents.selectors';
import { CountryCode, useCountryFeature, useFeature } from '@sonnen/shared-web';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { getGuideLeadId } from '+app/+guide/store/+lead.selectors';
import { StoreState } from '+app/store/store.interface';
import { storeProvider } from '+app/store/store.provider';
import { hasArticleNumber } from '+battery/store/battery.selectors';
import { hasInstalledBattery } from '+battery/store/battery.selectors';
import { CONFIG } from '+config';
import { CountryFeatureName } from '+config/countryFlags';
import { FeatureName } from '+config/featureFlags';
import {
  getContractCountryCode,
  getFinancialServiceProvider,
  hasContractStatusActivated,
  hasContractTypeSonnenFlatHome,
  hasContractTypeSonnenFlatX,
  hasSonnenConnectAuContract,
  hasSonnenControlContract,
  hasSonnenEnergiaItContract,
  hasSonnenEnergyContract,
  hasSonnenStrom2Contract,
  hasSonnenStromContract,
  isProductMigrationAvailable,
} from '+contract/store/contract.selectors';
import { FinancialServiceProvider } from '+contract/store/types/contract.interface';
import { isFlatOfferAccepted } from '+guide/+purchase/store/+purchase.selectors';
import { hasContactForm, hasFaqList } from '+help/store/help.helpers';
import { isHelpAndSupportSubmitSuccessful } from '+legacy/core/app/app.selectors';
import { RouteName } from '+router/router.types';
import { getActiveSite, isSiteWithContract, isSiteWithSmartmeter } from '+shared/store/site/site.selectors';
import { getUserCountryCode, isUserCountry } from '+shared/store/user/user.selectors';

export type RoutesAccess = Record<RouteName, boolean>;

export const getRoutesAccess = (state?: StoreState): RoutesAccess => {
  const storeState = state
    ? state
    : storeProvider.getState();

  const activeSite = getActiveSite(storeState);
  const isItalianUser = isUserCountry(CountryCode.IT)(storeState);
  const isSonnenLead = !!(storeState.guide.common.lead && storeState.guide.common.lead.source === 'sonnen');
  const hasBattery = hasInstalledBattery(storeState);
  const hasContract = isSiteWithContract(storeState);
  const userCountryCode = getUserCountryCode(storeState);
  const hasHelpAndSupport = hasFaqList(userCountryCode) || hasContactForm();
  const hasTaxPage = hasBattery && useCountryFeature(CountryFeatureName.TAXATION_HELP).isEnabled;
  const hasHelpAndSupportConfirmation = isHelpAndSupportSubmitSuccessful(storeState);
  const hasBatteryDownloads = hasArticleNumber(storeState);
  const hasSonnenStrom = hasSonnenStromContract(storeState);
  const hasSonnenStrom2 = hasSonnenStrom2Contract(storeState);
  const hasSonnenTariff = hasSonnenEnergyContract(storeState);
  const hasSonnenFlatHome = hasContractTypeSonnenFlatHome(storeState);
  const hasSonnenFlatX = hasContractTypeSonnenFlatX(storeState);
  const hasSonnenConnectAu = hasSonnenConnectAuContract(storeState);
  const hasSonnenControl = hasSonnenControlContract(storeState);
  const hasSonnenEnergy = hasSonnenEnergiaItContract(storeState);
  const hasRecruitFriends = useCountryFeature(CountryFeatureName.RECRUIT_FRIENDS).isEnabled;
  const hasFeedback = useCountryFeature(CountryFeatureName.FEEDBACK).isEnabled;
  const hasSonnenDrive = useCountryFeature(CountryFeatureName.SONNEN_DRIVE).isEnabled;
  const hasSmartmeter = isSiteWithSmartmeter(storeState);
  const contractCountryCode = getContractCountryCode(storeState);
  const financialServiceProvider = getFinancialServiceProvider(storeState);
  const hasSystemDocuments = Boolean(getOpportunities(storeState).length);
  const isContractStatusActivated = hasContractStatusActivated(storeState);
  const isSonnenFlatAccessible = hasContract && isContractStatusActivated && (hasSonnenFlatHome || hasSonnenFlatX);
  const isSonnenTariffAccessible =  hasContract && (hasSonnenTariff || hasSonnenStrom || hasSonnenStrom2);
  const isSonnenEnergyAccessible = (hasContract && hasSonnenEnergy) && isItalianUser;
  const isSonnenMigrationAccessible = isProductMigrationAvailable(storeState);
  const isUserReadingsAccessible = (hasContract && (hasSonnenStrom || hasSonnenStrom2)) || isSonnenFlatAccessible;
  const isDocumentsInvoicesPageAccessible = useFeature(FeatureName.DOCUMENTS_INVOICES).isEnabled && hasContract
    && ((contractCountryCode === CountryCode.DE
      && (hasSonnenFlatX || financialServiceProvider === FinancialServiceProvider.ZUORA))
    ||  hasSonnenConnectAu || hasSonnenControl || hasSonnenStrom2);
  const isDocumentsSystemDocsPageAccessible =
    useFeature(FeatureName.DOCUMENTS_SYSTEM_DOCS).isEnabled
    && useCountryFeature(CountryFeatureName.SYSTEM_DOCUMENTS).isEnabled
    && hasSystemDocuments;

  const isLoggedIn = isAuthenticated(storeState);
  const hasLeadId = Boolean(getGuideLeadId(storeState));
  const hasAcceptedOffer = Boolean(isFlatOfferAccepted(storeState));
  const isGuideAvailable = !isLoggedIn
    || (
      isLoggedIn
      && hasLeadId
      && hasAcceptedOffer
      && useCountryFeature(CountryFeatureName.CUSTOMER_GUIDANCE).isEnabled
      && useFeature(FeatureName.GUIDE_PAGES_IN_CP).isEnabled
    );
  const isGuideDiscoveryAvailable = isSonnenLead;

  return {
    [RouteName.ROOT]: true,

    [RouteName.LOGOUT]: true,

    [RouteName.DASHBOARD]: true,

    [RouteName.ANALYSIS]: !!activeSite && (hasBattery || hasSmartmeter),

    [RouteName.LIVE_STATE]: hasBattery && !hasSmartmeter,

    [RouteName.COMMUNITY]: true,

    [RouteName.FLAT]: isSonnenFlatAccessible,

    [RouteName.FLAT_CUSTOMER]: isSonnenFlatAccessible,
    [RouteName.FLAT_OVERVIEW]: isSonnenFlatAccessible,
    [RouteName.FLAT_USER_READINGS]: isUserReadingsAccessible,
    [RouteName.FLAT_NEW_CONTRACT]: isSonnenMigrationAccessible,
    [RouteName.FLAT_MIGRATION]: isSonnenMigrationAccessible,

    [RouteName.TARIFF]: isSonnenTariffAccessible,
    [RouteName.TARIFF_CUSTOMER]: isSonnenTariffAccessible,
    [RouteName.TARIFF_OVERVIEW]: isSonnenTariffAccessible,
    [RouteName.TARIFF_USER_READINGS]: isUserReadingsAccessible,

    [RouteName.ENERGY]: isSonnenEnergyAccessible,
    [RouteName.ENERGY_CUSTOMER]: isSonnenEnergyAccessible,
    [RouteName.ENERGY_OVERVIEW]: isSonnenEnergyAccessible,
    [RouteName.ENERGY_DOCUMENTS]: isSonnenEnergyAccessible,

    [RouteName.BATTERY]: hasBattery,
    [RouteName.BATTERY_OVERVIEW]: hasBattery,
    [RouteName.BATTERY_DETAILS]: hasBattery,
    [RouteName.BATTERY_DOWNLOADS]: hasBatteryDownloads,

    [RouteName.DOCUMENTS]:
       isDocumentsInvoicesPageAccessible || isDocumentsSystemDocsPageAccessible,
    [RouteName.DOCUMENTS_CONTRACTS]: true,
    [RouteName.DOCUMENTS_INVOICES]: isDocumentsInvoicesPageAccessible,
    [RouteName.DOCUMENTS_SYSTEM_DOCS]: isDocumentsSystemDocsPageAccessible,

    [RouteName.SONNEN_DRIVE]: hasSonnenDrive,

    [RouteName.RECRUIT_FRIENDS]: hasRecruitFriends,

    [RouteName.MY_ACCOUNT]: true,
    [RouteName.MY_ACCOUNT_ABOUT]: true,
    [RouteName.MY_ACCOUNT_PASSWORD]: true,
    [RouteName.MY_ACCOUNT_SETTINGS]: true,

    [RouteName.HELP_AND_SUPPORT]: hasHelpAndSupport || hasTaxPage,
    [RouteName.HELP_AND_SUPPORT_OVERVIEW]: hasHelpAndSupport,
    [RouteName.HELP_AND_SUPPORT_CONFIRMATION]: CONFIG.IS_MOBILE
      ? hasHelpAndSupport && hasHelpAndSupportConfirmation
      : hasHelpAndSupport,
    [RouteName.HELP_AND_SUPPORT_TAX]: hasTaxPage,

    [RouteName.FEEDBACK]: CONFIG.IS_MOBILE && hasFeedback,

    [RouteName.DEVELOPER]: CONFIG.IS_DEV,

    [RouteName.LOGIN]: true,

    [RouteName.GUIDE]: true,
    [RouteName.GUIDE_OVERVIEW]: isGuideAvailable,
    [RouteName.GUIDE_PRODUCT_INFO]: isGuideAvailable,
    [RouteName.GUIDE_PHASES]: isGuideAvailable,
    [RouteName.GUIDE_DOCUMENTS]: isGuideAvailable,
    [RouteName.GUIDE_CONTACT]: isGuideAvailable,
    [RouteName.GUIDE_DISCOVERY]: isGuideAvailable && isGuideDiscoveryAvailable,
    [RouteName.GUIDE_DISCOVERY_STEP]: isGuideAvailable && isGuideDiscoveryAvailable,
    [RouteName.GUIDE_PURCHASE]: isGuideAvailable,
    [RouteName.GUIDE_PURCHASE_STEP]: isGuideAvailable,
    [RouteName.GUIDE_ACCEPTANCE]: isGuideAvailable,
    [RouteName.GUIDE_ACCEPTANCE_ID]: isGuideAvailable,
    [RouteName.GUIDE_ACCEPTANCE_ID_HARDWARE]: isGuideAvailable,
    [RouteName.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS]: isGuideAvailable,
    [RouteName.GUIDE_SETUP]: isGuideAvailable,
    [RouteName.GUIDE_SETUP_STEP]: isGuideAvailable,
    [RouteName.GUIDE_USAGE]: isGuideAvailable,
    [RouteName.GUIDE_MY_ACCOUNT]: isGuideAvailable,
    [RouteName.GUIDE_LOGOUT]: isGuideAvailable,

    [RouteName.DEMO]: true,
    [RouteName.DEMO_BATTERY]: true,
    [RouteName.DEMO_HELLO]: true,
    [RouteName.DEMO_DASHBOARD]: true,
    [RouteName.DEMO_LIVE_STATE]: true,
    [RouteName.DEMO_COMMUNITY]: true,
    [RouteName.DEMO_ANALYSIS]: true,
    [RouteName.DEMO_FLAT]: true,
  };
};
