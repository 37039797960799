import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { METER_ACTIONS, MeterActions } from './meter.actions';
import { GET_METER_INSTALLATION_QUERY, initialState, MeterState, SEND_USER_READINGS_QUERY } from './meter.state';

export const reducer = createReducer<MeterState, MeterActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case METER_ACTIONS.SET_METER_INSTALLATION_DATA:
        return {
          ...state,
          meterInstallationData: action.meterInstallation,
        };
      case METER_ACTIONS.CLEAR_METER_DATA:
        return initialState;
      default:
        return queryReducer(state, action, [
          GET_METER_INSTALLATION_QUERY,
          SEND_USER_READINGS_QUERY,
        ]);
    }
  },
);
