import { RouteName, RouterNavConfig } from '../router.types';

export const mainNavWebConfig: RouterNavConfig = [
  RouteName.DASHBOARD,
  RouteName.LIVE_STATE,
  RouteName.ANALYSIS,
  {
    main: RouteName.GUIDE_PHASES,
    subRoutes: [
      RouteName.GUIDE_DISCOVERY,
      RouteName.GUIDE_PURCHASE,
      RouteName.GUIDE_SETUP,
      RouteName.GUIDE_USAGE,
    ],
  },
  RouteName.COMMUNITY,
  {
    main: RouteName.FLAT,
    subRoutes: [
      RouteName.FLAT_OVERVIEW,
      RouteName.FLAT_CUSTOMER,
      RouteName.FLAT_USER_READINGS,
      RouteName.FLAT_NEW_CONTRACT,
    ],
  },
  {
    main: RouteName.TARIFF,
    subRoutes: [
      RouteName.TARIFF_OVERVIEW,
      RouteName.TARIFF_CUSTOMER,
      RouteName.TARIFF_USER_READINGS,
    ],
  },
  {
    main: RouteName.ENERGY,
    subRoutes: [
      RouteName.ENERGY_OVERVIEW,
      RouteName.ENERGY_CUSTOMER,
      RouteName.ENERGY_DOCUMENTS,
    ],
  },
  {
    main: RouteName.BATTERY,
    subRoutes: [
      RouteName.BATTERY_OVERVIEW,
      RouteName.BATTERY_DETAILS,
      RouteName.BATTERY_DOWNLOADS,
    ],
  },
  {
    main: RouteName.DOCUMENTS,
    subRoutes: [
      RouteName.DOCUMENTS_CONTRACTS,
      RouteName.DOCUMENTS_INVOICES,
      RouteName.DOCUMENTS_SYSTEM_DOCS,
    ],
  },
  RouteName.RECRUIT_FRIENDS,
  RouteName.SONNEN_DRIVE,
  {
    main: RouteName.HELP_AND_SUPPORT,
    subRoutes: [
      RouteName.HELP_AND_SUPPORT_OVERVIEW,
      RouteName.HELP_AND_SUPPORT_TAX,
    ],
  },
  RouteName.GUIDE_PRODUCT_INFO,
  RouteName.GUIDE_DOCUMENTS,
  RouteName.GUIDE_CONTACT,
];

export const mainNavWebHeaderConfig: RouterNavConfig = [
  RouteName.MY_ACCOUNT,
  RouteName.LOGOUT,
];

export const mainNavMobileConfig: RouterNavConfig = [
  RouteName.LIVE_STATE,
  RouteName.ANALYSIS,
  RouteName.COMMUNITY,
  {
    main: RouteName.FLAT,
    subRoutes: [
      RouteName.FLAT_NEW_CONTRACT,
      RouteName.FLAT_OVERVIEW,
      RouteName.FLAT_CUSTOMER,
      RouteName.FLAT_USER_READINGS,
    ],
  },
  {
    main: RouteName.TARIFF,
    subRoutes: [
      RouteName.TARIFF_OVERVIEW,
      RouteName.TARIFF_CUSTOMER,
      RouteName.TARIFF_USER_READINGS,
    ],
  },
  {
    main: RouteName.ENERGY,
    subRoutes: [
      RouteName.ENERGY_OVERVIEW,
      RouteName.ENERGY_CUSTOMER,
      RouteName.ENERGY_DOCUMENTS,
    ],
  },
  {
    main: RouteName.BATTERY,
    subRoutes: [
      RouteName.BATTERY_OVERVIEW,
      RouteName.BATTERY_DETAILS,
      RouteName.BATTERY_DOWNLOADS,
    ],
  },
  {
    main: RouteName.DOCUMENTS,
    subRoutes: [
      RouteName.DOCUMENTS_CONTRACTS,
      RouteName.DOCUMENTS_INVOICES,
      RouteName.DOCUMENTS_SYSTEM_DOCS,
    ],
  },
  RouteName.RECRUIT_FRIENDS,
  RouteName.SONNEN_DRIVE,
  {
    main: RouteName.HELP_AND_SUPPORT,
    subRoutes: [
      RouteName.HELP_AND_SUPPORT_OVERVIEW,
      RouteName.HELP_AND_SUPPORT_TAX,
    ],
  },
  RouteName.FEEDBACK,
];
