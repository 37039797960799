import { UserReading } from '+user-readings/components/UserReadingsForm/types/UserReadingsForm.interface';
import { Query } from '../query/query.state';
import { MeterInstallation } from './types/meterInstallation.interface';

export const GET_METER_INSTALLATION_QUERY = 'getMeterInstallationQuery';
export const SEND_USER_READINGS_QUERY = 'sendUserReadings';

export interface MeterState {
  meterInstallationData: MeterInstallation | undefined;
  [GET_METER_INSTALLATION_QUERY]: Query<MeterInstallation>;
  [SEND_USER_READINGS_QUERY]: Query<UserReading>;
}

export const initialState: MeterState = {
  meterInstallationData: undefined,
  [GET_METER_INSTALLATION_QUERY]: {},
  [SEND_USER_READINGS_QUERY]: {},
};
