import * as classNames from 'classnames';
import { Field } from 'formik';
import * as moment from 'moment';
import * as React from 'react';

import { validateField } from '+shared/helpers/form.helper';
import { useElementDimensions } from '+shared/hooks/useElementDimensions';
import { FormInputProps } from './FormInput.types';
import { ObservableInputElement } from './FormInputObservable.component';

import './FormInput.component.scss';

export const FormInput = <T extends any = any>({
  form,
  name,
  unit,
  children,
  label,
  placeholder,
  type = 'text',
  disabled = false,
  className,
  horizontalLabel = false,
  hasNoGap = false,
  id,
  maxDate,
  onClick,
  valueFormatter,
  mask = {},
  autocomplete,
  dataTestId,
  labelClass,
  inputClass,
}: FormInputProps<T>) => {
  const [unitElementRef, dimensions] = useElementDimensions();
  const validation = validateField(name, form);

  return (
    <div className={classNames('c-form-input', className, {
      'c-form-input--no-gap': hasNoGap && !validation.hasError,
      'c-form-input--disabled': disabled,
      'c-form-input--horizontal': horizontalLabel,
    })}>
      {label && (
        <label className={classNames('c-form-input__label', labelClass, {
          'c-form-input__label--horizontal': horizontalLabel,
        })}>
          {label}
        </label>
      )}
      <div className={classNames('c-form-input__container', inputClass)}>
        <Field
          component={ObservableInputElement}
          style={{ paddingRight: dimensions ? dimensions.width : undefined }}
          className={classNames('c-form-input__field', {
            'c-form-input__field--error': validation.hasError,
            'c-form-input__field--pointer': maxDate,
          })}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          max={moment(maxDate).format('YYYY-MM-DD')}
          onClick={onClick}
          // TODO atm only input of type `date` works properly with valueFormatter.
          // Need to add a masking component/library to enable formatting other input types.
          valueFormatter={valueFormatter}
          mask={mask}
          autocomplete={autocomplete}
          data-test-id={dataTestId + '-input'}
        />
        {unit && (
          <div className={`c-form-input__unit-wrapper`} ref={unitElementRef}>
            <span className={'c-form-input__unit'}>
              {unit}
            </span>
          </div>
        )}
        <div className={'c-form-input__component'}>
          {children}
        </div>
      </div>
      <div className={'c-form-input__error'}>
        {validation.hasError && (
          <div
            data-test-id={dataTestId + '-error-message'}
            className={'c-form-input__error-message'}
          >
            {validation.error}
          </div>
        )}
      </div>
    </div>
  );
};
