import { MeterReadingsPayload } from '+shared/store/meter/types/meterReadings.interface';
import { ActionsUnion, createAction } from '+utils/redux';
import { MeterInstallation } from './types/meterInstallation.interface';

export enum METER_ACTIONS {
  GET_METER_INSTALLATION_DATA = '[Meter] GET_METER_INSTALLATION_DATA',
  SET_METER_INSTALLATION_DATA = '[Meter] SET_METER_INSTALLATION_DATA',
  SEND_USER_READINGS = '[Meter] SEND_USER_READINGS',
  CLEAR_METER_DATA = '[Meter] CLEAR_METER_DATA',
}

export const MeterActions = {
  getMeterInstallationData: () => createAction(METER_ACTIONS.GET_METER_INSTALLATION_DATA),
  setMeterInstallationData: (meterInstallation: MeterInstallation) =>
    createAction(METER_ACTIONS.SET_METER_INSTALLATION_DATA, { meterInstallation }),
  sendUserReadings: (userReadings: MeterReadingsPayload) =>
    createAction(METER_ACTIONS.SEND_USER_READINGS, { userReadings }),
  clearMeterData: () => createAction(METER_ACTIONS.CLEAR_METER_DATA),
};

export type MeterActions = ActionsUnion<typeof MeterActions>;
