import { StoreState } from '+app/store/store.interface';
import { GET_METER_INSTALLATION_QUERY, SEND_USER_READINGS_QUERY } from '+shared/store/meter/meter.state';
import { getStatus, isQueryPending } from '+utils/query.util';
import { createSelector } from 'reselect';

export const getMeterState = (store: StoreState) => store.meter;

export const getMeterData = createSelector(
  getMeterState,
  state => state.meterInstallationData,
);

export const getMeteringConceptCapable = createSelector(
  getMeterData,
  meterInstallationData => meterInstallationData?.meteringConceptCapable,
);

export const getMeterGridId = createSelector(
  getMeterData,
  meterData => meterData?.gridMeterId,
);

export const getVppCategory = createSelector(
  getMeterData,
  meterData => meterData?.vppCategory,
);

export const getSendUserReadingsQueryStatus = createSelector(
  getMeterState,
  state => getStatus(state[SEND_USER_READINGS_QUERY]),
);

export const isSendUserReadingsQueryPending = createSelector(
  getMeterState,
  state => isQueryPending(getStatus(state[SEND_USER_READINGS_QUERY])),
);

export const getMeterInstallationQueryStatus = createSelector(
  getMeterState,
  state => getStatus(state[GET_METER_INSTALLATION_QUERY]),
);
