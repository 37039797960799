import { NotificationActions } from '+shared/store/notification/notification.actions';
import { createUserReadingsApiError } from '+shared/store/notification/notification.factory';
import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getMeterInstallationId } from '+app/+dashboard/+contract/store/contract.selectors';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { METER_ACTIONS, MeterActions } from './meter.actions';
import { MeterRepository } from './meter.repository';
import { GET_METER_INSTALLATION_QUERY, SEND_USER_READINGS_QUERY } from './meter.state';

export function* getMeterInstallationData(): SagaIterator {
  const meterInstallationId = yield select(getMeterInstallationId);

  yield processQuery(
    GET_METER_INSTALLATION_QUERY,
    MeterRepository.getMeterInstallationData,
    {
      onSuccess: res => dataGuard(MeterActions.setMeterInstallationData)(res?.element),
    },
  )(meterInstallationId);
}

export function* sendUserReadings(action: ReturnType<typeof MeterActions.sendUserReadings>): SagaIterator {
  yield processQuery(
    SEND_USER_READINGS_QUERY,
    MeterRepository.postMeterReading,
    {
      onFailure: (err) => put(NotificationActions.addNotification(createUserReadingsApiError([action]))),
    },
  )(action.userReadings);
}

export const meterSagas = combineSagas([
  takeLatest(METER_ACTIONS.GET_METER_INSTALLATION_DATA, getMeterInstallationData),
  takeLatest(METER_ACTIONS.SEND_USER_READINGS, sendUserReadings),
]);
