import { get } from 'lodash/fp';

export type FieldValueFormatter = (value: any) => string;

export const validateField = (name: string, form: any) => {
  const error = get(name)(form.errors);
  const isTouched = get(name)(form.touched);
  const hasError = !!error && isTouched;

  return {
    error,
    hasError,
  };
};

export const formatFieldValue = (value: any, valueFormatter?: FieldValueFormatter) => {
  if (valueFormatter) {
    return valueFormatter(value);
  }

  return value;
};
